
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  props: {},
  setup() {
    const state = reactive<{
      conmpanyName: string;
      recordNumber: string;
    }>({
      conmpanyName: "苏州欧孚网络科技有限公司",
      recordNumber: "苏ICP备16039517号-4",
    });

    return {
      ...toRefs(state),
    };
  },
});
