/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from "@/utils/http";
export default {
  custSignPage(params: any) {
    return http.post(`client/api/v1/cust/zp/cust_sign/custSignPage`, params);
  },
  sendPost(params: any) {
    return http.post(`client/api/v1/cust/zp/cust_sign/save2NewPost`, params);
  },
  addOne(params: any) {
    return http.post(`client/api/v1/cust/zp/accountInfo/addone`, params);
  },
  // 修改候选人基础信息
  updateBase(params: any) {
    return http.post(`client/api/v1/cust/zp/accountInfo/update/base`, params);
  },
  // 修改候选人求职期望
  updateForward(params: any) {
    return http.post(
      `client/api/v1/cust/zp/accountInfo/update/forward`,
      params
    );
  },
  // 修改候选人简历附件
  updateResumeUrl(params: any) {
    return http.post(
      `client/api/v1/cust/zp/accountInfo/update/resumeUrl`,
      params
    );
  },
  // 创建工作经历
  saveWork(params: any) {
    return http.post(`client/api/v1/cust/zp/exp/work/save`, params);
  },
  // // 修改工作经历
  // updateWork(params: any) {
  //   return http.post(`client/api/v1/cust/zp/exp/work/update`, params);
  // },
  // 删除工作经历
  deleteWork(params: any) {
    return http.delete(
      `client/api/v1/cust/zp/exp/work/delete?workExpId=${params.workexpId}`
    );
  },
  // 创建教育经历
  saveEdu(params: any) {
    return http.post(`client/api/v1/cust/zp/exp/edu/save`, params);
  },
  // 修改教育经历
  // updateEdu(params: any) {
  //   return http.post(`client/api/v1/cust/zp/exp/edu/update`, params);
  // },
  // 删除教育经历
  deleteEdu(params: any) {
    return http.delete(
      `client/api/v1/cust/zp/exp/edu/delete?eduExpId=${params.eduExpId}`
    );
  },
  // 创建项目经历
  saveProject(params: any) {
    return http.post(`client/api/v1/cust/zp/exp/project/save`, params);
  },
  // 修改项目经历
  // updateProject(params: any) {
  //   return http.post(`client/api/v1/cust/zp/exp/project/update`, params);
  // },
  // 删除项目经历
  deleteProject(params: any) {
    return http.delete(
      `client/api/v1/cust/zp/exp/project/delete?projectExpId=${params.projectExpId}`
    );
  },
  // 批量创建工作经历
  saveWorkBatch(params: any) {
    return http.post(`client/api/v1/cust/zp/exp/work/saveBatch`, params);
  },
  // 批量修改工作经历
  // updateWorkBatch(params: any) {
  //   return http.post(`client/api/v1/cust/zp/exp/work/updateBatch`, params);
  // },
  // 批量创建教育经历
  saveEduBatch(params: any) {
    return http.post(`client/api/v1/cust/zp/exp/edu/saveBatch`, params);
  },
  // 批量修改教育经历
  // updateEduBatch(params: any) {
  //   return http.post(`client/api/v1/cust/zp/exp/edu/updateBatch`, params);
  // },
  // 批量创建项目经历
  saveProjectBatch(params: any) {
    return http.post(`client/api/v1/cust/zp/exp/project/saveBatch`, params);
  },
  // 批量修改项目经历
  // updateProjectBatch(params: any) {
  //   return http.post(`client/api/v1/cust/zp/exp/project/updateBatch`, params);
  // },
  // 获取人员详情
  userDetail() {
    return http.get(`client/api/v1/cust/zp/accountInfo/detail`);
  },
};
