
import { defineComponent, reactive, toRefs } from "vue";
import BreadCrumb from "@/components/pc/breadCrumb.vue";

export default defineComponent({
  name: "Home",
  components: {
    BreadCrumb,
  },
  setup() {
    const state = reactive<{
      addressList: any;
    }>({
      addressList: [],
    });

    return {
      ...toRefs(state),
    };
  },
});
