/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from "@/utils/http";
export default {
  postCityList() {
    return http.get(`client/api/v1/cust/zp/post/city/list`);
  },
  postList(parmas: any) {
    return http.post(`client/api/v1/cust/zp/post/page`, parmas);
  },
  postDetail(parmas: any) {
    return http.get(
      `client/api/v1/cust/zp/post/detail?postId=${parmas.postId}`
    );
  },
};
