import { createStore } from "vuex";
import appStore from "./appStore";
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appStore,
  },
});
