
import { defineComponent, onBeforeMount, reactive, toRefs, watch } from "vue";
import { useRoute, RouteLocationMatched } from "vue-router";
import { compile } from "path-to-regexp";
import router from "@/router";
import { ArrowRight } from "@element-plus/icons-vue";
export default defineComponent({
  setup() {
    const currentRoute = useRoute();
    const pathCompile = (path: string) => {
      const { params } = currentRoute;
      const toPath = compile(path);
      return toPath(params);
    };

    const state = reactive({
      breadcrumbs: [] as Array<RouteLocationMatched>,
      getBreadcrumb: () => {
        let matched = currentRoute.matched.filter(
          (item) => item.meta && item.meta.title
        );
        const frist = matched[0];
        if (!state.isDashboard(frist)) {
          matched = [
            { path: "/postList", meta: { title: "首页" } } as any,
          ].concat(matched);
        }
        state.breadcrumbs = matched.filter((item) => {
          return item.meta && item.meta.title && item.meta.breadcrumb !== false;
        });
      },
      isDashboard(route: RouteLocationMatched) {
        const name = route && route.name;
        if (!name) {
          return false;
        }
        return name.toString().trim() === "PostList";
      },
      handleLink(item: any, index: number) {
        const { redirect, path } = item;
        if (index === state.breadcrumbs.length - 1) {
          return;
        }
        if (redirect) {
          router.push(redirect).catch((err) => {
            console.warn(err);
          });
          return;
        }
        router.push(pathCompile(path)).catch((err) => {
          console.warn(err);
        });
      },
    });

    watch(
      () => currentRoute.path,
      (path) => {
        if (path.startsWith("/redirect/")) {
          return;
        }
        state.getBreadcrumb();
      }
    );
    onBeforeMount(() => {
      state.getBreadcrumb();
    });

    return {
      ...toRefs(state),
      ArrowRight,
    };
  },
});
