
import { defineComponent, reactive, toRefs, ref } from "vue";
import { ElForm } from "element-plus";
import { User, Lock } from "@element-plus/icons-vue";
import { recruitService } from "@/api/index";
import { ElMessage } from "element-plus/lib/components";
import { useStore } from "vuex";
import { sessionStorage } from "@/utils/util";

export default defineComponent({
  props: {
    successCallBack: Function,
  },
  setup(props) {
    type FormInstance = InstanceType<typeof ElForm>;
    const formRef = ref<FormInstance>();
    const store = useStore();
    const state = reactive<{
      codeMsg: string;
      countDown: number;
      disabledBtn: boolean;
      isLoading: boolean;
    }>({
      codeMsg: "获取验证码",
      countDown: 59,
      disabledBtn: false,
      isLoading: false,
    });
    const checkPhone = (rule: any, value: any, callback: any) => {
      if (!value) {
        return callback(new Error("请输入手机号！"));
      } else {
        if (!/^1[123456789]\d{9}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
          return;
        }
        callback();
      }
    };
    const checkCode = (rule: any, value: any, callback: any) => {
      if (!value) {
        return callback(new Error("请输入验证码！"));
      } else {
        callback();
      }
    };
    const ruleForm = reactive({
      mobile: "",
      code: "",
    });
    const rules = reactive({
      mobile: [{ validator: checkPhone, trigger: ["blur"] }],
      code: [{ validator: checkCode, trigger: ["blur", "change"] }],
    });
    let t: any;
    function setTime() {
      if (state.countDown === 0) {
        state.codeMsg = "获取验证码";
        state.countDown = 59;
        state.disabledBtn = false;
      } else {
        state.codeMsg = state.countDown + "秒";
        state.countDown--;
        state.disabledBtn = true;
        t = setTimeout(() => {
          setTime();
        }, 1000);
      }
    }
    async function getCode() {
      console.log(112);
      formRef.value?.validateField("mobile", (val) => {
        if (val) {
          setTime();
          recruitService.login
            .getCode({
              mobile: ruleForm.mobile,
            })
            .then((val) => {
              console.log(val);
            })
            .catch((val) => {
              state.codeMsg = "获取验证码";
              state.countDown = 59;
              state.disabledBtn = false;
              clearTimeout(t);
              if (val.isHttpStatus) {
                console.log(val.statusText);
              } else {
                ElMessage.error(val.message || "错误");
              }
            });
        } else {
          return new Error("请输入手机号！");
        }
      });
    }
    const submitForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate(async (valid) => {
        if (valid) {
          state.isLoading = true;
          recruitService.login
            .login(ruleForm)
            .then((val) => {
              store.dispatch("setUserInfo", ruleForm);
              store.dispatch("setToken", val.data.token);
              sessionStorage.set("userInfo", ruleForm);
              sessionStorage.set("token", val.data.token);
              ElMessage.success("登录成功！");
              recruitService.user
                .userDetail()
                .then((val) => {
                  if (val.data) {
                    store.dispatch("setAccountId", val.data.accountId);
                    sessionStorage.set("accountId", val.data.accountId);
                  }
                })
                .catch((val) => {
                  if (val.isHttpStatus) {
                    console.log(val.statusText);
                  } else {
                    ElMessage.error(val.message || "错误");
                  }
                });
              state.isLoading = false;
              if (props.successCallBack) {
                props.successCallBack();
              }
            })
            .catch((val) => {
              state.isLoading = false;
              if (val.isHttpStatus) {
                console.log(val.statusText);
              } else {
                ElMessage.error(val.message || "错误");
              }
            });
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };
    return {
      ...toRefs(state),
      formRef,
      checkPhone,
      checkCode,
      setTime,
      ruleForm,
      rules,
      getCode,
      submitForm,
      User,
      Lock,
    };
  },
});
