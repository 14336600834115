/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const sppStore = {
  state: {
    token: null,
    userInfo: null,
    accountId: null,
  },
  mutations: {
    tokenChange(state: any, value: any) {
      state.token = value;
    },
    userInfoChange(state: any, value: any) {
      state.userInfo = value;
    },
    accountIdChange(state: any, value: any) {
      state.accountId = value;
    },
  },
  actions: {
    setToken(context: any, value: any) {
      context.commit("tokenChange", value);
    },
    cleanToken(context: any) {
      context.commit("tokenChange", null);
    },
    setUserInfo(context: any, value: any) {
      context.commit("userInfoChange", value);
    },
    cleanUserInfo(context: any) {
      context.commit("userInfoChange", null);
    },
    setAccountId(context: any, value: any) {
      context.commit("accountIdChange", value);
    },
    cleanAccountId(context: any) {
      context.commit("accountIdChange", null);
    },
  },
};
export default sppStore;
