/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from "@/utils/http";
export const uploadAction = `client/common/file/uploadFile`;
export default {
  regionList() {
    return http.get(`client/common/region/allTree`);
  },
  uploadFile(formData: any) {
    return http.post(`client/common/file/uploadFile`, formData);
  },
  dictList(type: any) {
    return http.get(`client/sys/dict/all?dictTypeCode=${type}`);
  },
  cityTree() {
    return http.get(`client/common/region/cityTree`);
  },
};
