/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from "@/utils/http";
export default {
  login(params: any) {
    return http.post("client/api/v1/cust/zp/login/login", params);
  },
  getCorpInfo() {
    return http.get("client/api/v1/cust/zp/login/getCorpInfo");
  },
  getCode(params: any) {
    return http.post("client/api/v1/custom/login/verificationV2", params);
  },
};
