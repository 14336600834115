
import { defineComponent, reactive, SetupContext, toRefs, inject } from "vue";

export default defineComponent({
  name: "v-preview-item",
  props: {
    label: [String, Number],
    labelAlign: String,
    labelWidth: String,
    width: String,
    content: [String, Number],
    type: String,
    dateType: String,
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setup(props: any, ctx: SetupContext) {
    const vPreview: any = inject("vPreview");
    const state = reactive({
      currentLabelWidth: props.labelWidth || vPreview.labelWidth,
      currentLabelAlign: props.labelAlign || vPreview.labelAlign,
      currentItemTopSpace: vPreview.itemTopSpace,
    });

    return {
      ...toRefs(state),
      getFileIcon(type: string) {
        if (["pdf"].includes(type)) {
          return "fa-file-pdf-o";
        } else if (["xls", "xlsx"].includes(type)) {
          return "fa-file-excel-o";
        } else if (["doc", "docx"].includes(type)) {
          return "fa-file-word-o";
        } else {
          return "fa-file-text-o";
        }
      },
      getFileName(file: string) {
        if (file) {
          return file.split("/").pop();
        } else {
          return "";
        }
      },
      openFile(file: string) {
        window.open(file);
      },
    };
  },
});
