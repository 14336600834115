import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// import qs from 'qs'
// import store from "@/store/index"; //已经设置路径别名，否则相对路径引用
import { ElMessage } from "element-plus/lib/components";
// import { useStore } from "vuex";
import store from "../store/index";
// let loading: any = null;
// const store = useStore();
import router from "@/router";
// import { useRouter } from "vue-router";
import { sessionStorage } from "@/utils/util";
const service = axios.create({
  timeout: 5000, //超时时间
  // 我们在请求接口的时候就不用写前面 会自动我们补全
  // transformRequest: data => qs.stringify(data)    //post请求参数处理,防止post请求跨域
});
// http request 拦截器
service.interceptors.request.use(
  (config) => {
    // loading = ElLoading.service({
    //   lock: true,
    //   text: "Loading",
    //   background: "rgba(0, 0, 0, 0.7)",
    // });
    if ((store.state as any).appStore.token) {
      (config as any).headers["token"] = (store.state as any).appStore.token;
    }
    (config as any).headers["entityId"] = "712656966467452928";
    return config;
  },
  (err) => {
    return err;
  }
);
const defaultSuccessCode = 0;
// http response 拦截器
service.interceptors.response.use(
  (response) => {
    // loading.close();
    const { data } = response;
    const code = data.code;
    const successCode = data.successCode ?? defaultSuccessCode;
    return code === successCode
      ? Promise.resolve(data as any)
      : Promise.reject(data as any);
    // return response;
    //接收返回数据..
    // const res = response.data
    //判断返回数据是否存在状态code和错误提示信息..
    // if (!res.code || !res.msg) {
    //     return showMessage('响应数据格式错误', "error")
    // }
    //判断状态code是否为指定数值(200)..
    // if (res.code != 200) {
    //     return showMessage(res.msg)
    // }
    // return res
  },
  (err) => {
    // loading.close();
    if (err.response) {
      switch (err.response.status) {
        case 401:
          ElMessage.error("登录信息过期，请重新登录！");
          store.dispatch("cleanToken");
          store.dispatch("cleanUserInfo");
          store.dispatch("cleanAccountId");
          sessionStorage.remove("accountId");
          sessionStorage.remove("token");
          sessionStorage.remove("userInfo");
          router.push("/");
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          break;
        case 403:
          ElMessage.error("不允许访问");
          break;
        case 404:
          ElMessage.error("请求接口无法找到");
          break;
        case 502:
          ElMessage.error("服务器维护中， 请稍后重试！");
          break;
        case 504:
          ElMessage.error("请求超时！");
          break;
        default:
          ElMessage.error(err.message);
          break;
      }
      err.response["isHttpStatus"] = true;
      return Promise.reject(err.response as any);
    } else {
      showMessage(err.message);
      return Promise.reject(err.response as any);
    }
  }
);

//封装错误提示信息..
function showMessage(message: any) {
  ElMessage({
    message: message, //错误提示信息
    type: "error", //显示类型
    duration: 3 * 1000, //展示时间
  });
  return Promise.reject();
}
export default service;

export interface RequestConfig extends AxiosRequestConfig {
  cache?: boolean;
  successCode?: number | string;
}
export interface BaseResponse<T = any> {
  [x: string]: any;
  code: number | string;
  data: T;
  message: string;
  successCode?: number | string;
  response: AxiosResponse<T>;
}
