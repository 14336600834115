import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import { globalRegister } from "@/utils/util";
// import ElementPlus from "element-plus";
// import zhCn from "element-plus/es/locale/lang/zh-cn";
import { vLoading } from "element-plus/es/components/loading/src/directive";
import "@/utils/element-plus-ui";
import "@/assets/css/style.scss";
import utils from "@/utils/util";
import vPreview from "@/components/preview.vue";
import vPreviewItem from "@/components/preview-item.vue";
const app = createApp(App);
app.directive("load", vLoading);
app.component("v-preview", vPreview);
app.component("v-preview-item", vPreviewItem);

// app.use(ElementPlus, { locale: zhCn });
// console.log(ElementPlus);
app.config.globalProperties["$utils"] = utils;
app
  .use(globalRegister)
  //   .use(ElementPlus, { locale: zhCn })
  .use(store)
  .use(router)
  .mount("#app");
