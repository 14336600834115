import post from "./post";
import login from "./login";
import common from "./common";
import user from "./user";
export const recruitService = {
  post,
  login,
  common,
  user,
};
