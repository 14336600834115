
import { defineComponent, reactive, toRefs, computed, onMounted } from "vue";
import Login from "@/components/pc/login.vue";
import { ArrowDown } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { sessionStorage } from "@/utils/util";
import { recruitService } from "@/api";
import { ElMessage } from "element-plus/lib/components";
export default defineComponent({
  props: {},
  components: {
    Login,
    ArrowDown,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const mobile = computed(() => {
      return store.state.appStore.userInfo?.mobile;
    });
    const hasToken = computed(() => {
      return store.state.appStore.token ? true : false;
    });
    const state = reactive<{
      url: string;
      dialogVisible: boolean;
      companyName: string;
    }>({
      url: "",
      dialogVisible: false,
      companyName: "博尔捷复合用工平台",
    });
    onMounted(() => {
      recruitService.login
        .getCorpInfo()
        .then((val) => {
          if (val.data.corpName) {
            state.companyName = val.data.corpName;
          }
          if (val.data.logoUrl) {
            state.url = val.data.logoUrl;
          }
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
    });
    function login() {
      state.dialogVisible = true;
    }
    function handleCommand(command: any) {
      switch (command) {
        case "mycv":
          if (sessionStorage.get("accountId")) {
            router.push(`/infoCv`).catch((err) => {
              console.warn(err);
            });
          } else {
            router.push(`/editCv`).catch((err) => {
              console.warn(err);
            });
          }

          break;
        case "record":
          router.push(`/record`).catch((err) => {
            console.warn(err);
          });
          break;
        case "logout":
          store.dispatch("cleanToken");
          store.dispatch("cleanUserInfo");
          store.dispatch("cleanAccountId");
          sessionStorage.remove("accountId");
          sessionStorage.remove("token");
          sessionStorage.remove("userInfo");
          router.push(`/`).catch((err) => {
            console.warn(err);
          });
          break;
        default:
          break;
      }
    }
    function toHome() {
      router.push(`/`).catch((err) => {
        console.warn(err);
      });
    }
    function handleClose(done: () => void) {
      loginSuccess();
      done();
    }
    function loginSuccess() {
      state.dialogVisible = false;
    }
    return {
      ...toRefs(state),
      login,
      handleClose,
      handleCommand,
      loginSuccess,
      store,
      mobile,
      hasToken,
      toHome,
    };
  },
});
