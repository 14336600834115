import { App } from "vue";
import "element-plus/theme-chalk/index.css";
import {
  ElButton,
  ElInput,
  ElImage,
  ElIcon,
  ElPagination,
  ElAffix,
  ElDialog,
  ElForm,
  ElFormItem,
  ElMessage,
  ElMessageBox,
  ElTag,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElDatePicker,
  ElAvatar,
  ElUpload,
  ElSelect,
  ElOption,
  ElRadioGroup,
  ElRadio,
  ElTabPane,
  ElTabs,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElCascader,
} from "element-plus/lib/components";
const components = [
  ElButton,
  ElInput,
  ElImage,
  ElIcon,
  ElPagination,
  ElAffix,
  ElDialog,
  ElForm,
  ElFormItem,
  ElMessage,
  ElMessageBox,
  ElTag,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElDatePicker,
  ElAvatar,
  ElUpload,
  ElSelect,
  ElOption,
  ElRadioGroup,
  ElRadio,
  ElTabPane,
  ElTabs,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElCascader,
];
export default function (app: App): void {
  for (const component of components) {
    app.component(component.name, component as any);
  }
}
