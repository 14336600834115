import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_config_provider, { locale: _ctx.locale }, {
      default: _withCtx(() => [
        _createVNode(_component_Header),
        (_ctx.isRouterAlive)
          ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_Footer)
      ]),
      _: 1
    }, 8, ["locale"])
  ]))
}