
import { defineComponent, reactive, SetupContext, toRefs } from "vue";

export default defineComponent({
  name: "v-preview",
  provide() {
    return {
      vPreview: this,
    };
  },
  props: {
    labelAlign: String,
    labelWidth: String,
    itemTopSpace: String,
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setup(props, ctx: SetupContext) {
    const state = reactive({});

    return {
      ...toRefs(state),
    };
  },
});
