/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { App } from "vue";
import registerElement from "./element-plus-ui";
import moment from "moment-timezone";
import "@vue/runtime-core";
moment.locale("zh-cn");
moment.tz.setDefault("Asia/Shanghai");
type Types =
  | "string"
  | "number"
  | "array"
  | "object"
  | "function"
  | "regexp"
  | "promise"
  | "null"
  | "blob"
  | "date"
  | "arraybuffer"
  | "undefined";
export class Utils {
  private idStart = 0;
  public guid() {
    return (this.idStart++).toString();
  }
  textareaFormat(val: string) {
    return val ? val.replace(/\r?\n/g, "<br/>") : "";
  }
  // 判断是否是图片类型
  isImage(str: string) {
    const imgType = ["png", "jpeg", "jpg", "gif", "svg"];
    const fileType = str.substring(str.lastIndexOf(".") + 1);
    if (imgType.indexOf(fileType) < 0) {
      return false;
    } else {
      return true;
    }
  }
  type(obj: any): Types {
    return {}.toString.call(obj).slice(8, -1).toLowerCase() as Types;
  }
  mobileFm(mobile: string) {
    if (mobile) {
      const str = mobile.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2");
      return str;
    } else {
      return "";
    }
  }
  /**
   * 时间戳格式化
   * @param timestamp timestamp
   * @param fmt YYYY-MM-DD HH:mm:ss
   * @returns {string}
   */
  dateFm(timestamp: number, fmt?: string) {
    if (timestamp === null || timestamp === undefined) return "";
    fmt = this.dateFmStr(fmt || "");
    return moment(timestamp * 1000).format(fmt);
  }
  strFmTimestamp(date: string) {
    date = date.replace(/\//g, "-");
    return moment(date).unix();
  }
  dateFmStr(fmtStr: string) {
    return fmtStr
      ? fmtStr.replace(/[ydh]/g, (substring) => substring.toUpperCase())
      : "YYYY-MM-DD";
  }
  dateTypeFormat(
    value: any,
    config?: { dateType?: string; dateSeparator?: string }
  ) {
    const dateType = config?.dateType || "date",
      dateSeparator = config?.dateSeparator || "-";

    if (dateType === "year") {
      return this.dateFm(value, "YYYY");
    } else if (dateType === "month") {
      return this.dateFm(value, `YYYY${dateSeparator}MM`);
    } else if (dateType === "date") {
      return this.dateFm(value, `YYYY${dateSeparator}MM${dateSeparator}DD`);
    } else if (dateType === "datetime") {
      return this.dateFm(
        value,
        `YYYY${dateSeparator}MM${dateSeparator}DD HH:mm`
      );
    } else if (dateType === "daterange") {
      return value
        ? value
            .split(",")
            .map((d: string) =>
              this.dateFm(Number(d), `YYYY${dateSeparator}MM${dateSeparator}DD`)
            )
            .join(" ~ ")
        : "";
    } else if (dateType === "monthrange") {
      return value
        ? value
            .split(",")
            .map((d: string) =>
              this.dateFm(Number(d), `YYYY${dateSeparator}MM`)
            )
            .join(" ~ ")
        : "";
    } else if (dateType === "datetimerange") {
      return value
        ? value
            .split(",")
            .map((d: string) =>
              this.dateFm(
                Number(d),
                `YYYY${dateSeparator}MM${dateSeparator}DD HH:mm`
              )
            )
            .join(" ~ ")
        : "";
    }
  }
  minToTime(min: number) {
    return (
      this.prefixInteger((min / 60) >> 0, 2) +
      ":" +
      this.prefixInteger(min % 60, 2)
    );
  }
  prefixInteger(num: number, length: number) {
    num = num || 0;
    if (num < 0) console.warn("num < 0");
    const max = Math.max(String(num).length, length);
    return (Array(length).join("0") + num).slice(-max);
  }
}
export default new Utils();
export const sessionStorage = {
  //存储
  set(key: string, value: any) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  },
  //取出数据
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  get<T>(key: string) {
    const value = window.sessionStorage.getItem(key);
    if (value && value != "undefined" && value != "null") {
      return JSON.parse(value);
    }
    return null;
  },
  // 删除数据
  remove(key: string) {
    window.sessionStorage.removeItem(key);
  },
};
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $utils: Utils;
  }
}
export function globalRegister(app: App): void {
  app.use(registerElement);
  // .use(ElementPlus, {
  //   locale: zhCn,
  // });
}
