import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store/index";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Home,
    redirect: "/postList",
    children: [
      {
        path: "postList",
        component: () => import("@/components/pc/postList.vue"),
        name: "PostList",
        meta: {
          title: "首页",
        },
      },
      {
        path: "postDetail",
        component: () => import("@/components/pc/postDetail.vue"),
        name: "PostDetail",
        meta: {
          title: "职位详情",
        },
      },
      {
        path: "editCv",
        component: () => import("@/components/pc/addCv.vue"),
        name: "EditCv",
        meta: {
          title: "简历编辑",
        },
      },
      {
        path: "infoCv",
        component: () => import("@/components/pc/detailCv.vue"),
        name: "infoCv",
        meta: {
          title: "我的简历",
        },
      },
      {
        path: "record",
        component: () => import("@/components/pc/deliveryRecord.vue"),
        name: "Record",
        meta: {
          title: "投递记录",
        },
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const noAuthList: any[] = ["PostList", "PostDetail"];
  if (!noAuthList.includes(to.name)) {
    if (!(store.state as any).appStore.token) {
      next({
        name: "PostList",
        replace: true,
      });
      return;
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      next();
    }
  } else {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    next();
  }
});

export default router;
