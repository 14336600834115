import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a0f509e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-breadcrumb-wrap" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_breadcrumb, {
      class: "app-breadcrumb",
      separator: "/"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: item.path
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                class: _normalizeClass({ 'last-breadcrumb': index === _ctx.breadcrumbs.length - 1 }),
                onClick: _withModifiers(($event: any) => (_ctx.handleLink(item, index)), ["prevent"])
              }, _toDisplayString(item.meta.title), 11, _hoisted_2)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ], 512)), [
    [_vShow, _ctx.breadcrumbs.length > 1]
  ])
}