
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  nextTick,
  provide,
} from "vue";
import { ElConfigProvider } from "element-plus/lib/components";
import Header from "@/components/pc/header.vue";
import Footer from "@/components/pc/footer.vue";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import { sessionStorage } from "@/utils/util";
import { useStore } from "vuex";
export default defineComponent({
  name: "app",
  components: {
    ElConfigProvider,
    Header,
    Footer,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      isRouterAlive: true,
    });
    onBeforeMount(() => {
      store.dispatch("setToken", sessionStorage.get("token"));
      store.dispatch("setUserInfo", sessionStorage.get("userInfo"));
    });
    provide("isRouterAlive", reload());
    function reload() {
      state.isRouterAlive = false;
      nextTick(() => {
        state.isRouterAlive = true;
      });
    }
    return {
      ...toRefs(state),
      reload,
      locale: zhCn,
    };
  },
});
